import { ReactNode } from 'react'
import { ErrorDetails } from './hooks/useVideoService'

export enum PlayerType {
  Bitmovin = 'bitmovin',
  Shaka = 'shaka',
}

// combine the two interfaces
export type TPlayerProps = {
  videoId: string
  videoStartTime?: number
  muted?: boolean
  chromeless?: boolean
  shouldRenderErrorOverlay?: boolean
  disableLoadingSpinner?: boolean // Used for Hero (WatchVideoRail.tsx), which should not have the loading spinner
  useOwnBufferSpinner?: boolean // Used for CW, because if children are passed to shaka then its own buffering spinner is missing
  shouldTrackVideoAnalytics?: boolean
  children?: ReactNode | undefined
  shouldRenderLoadingSpinner?: boolean
  isBackgroundVideo?: boolean
  controls?: 'web' | 'tv'
  image?: string
  start?: Date
  onPlayerCanPlayThrough?: () => void
  showLoadingUntilPlaying?: boolean
  VideoErrorView?: ({ error }: { error: ErrorDetails }) => JSX.Element
}

export type TSubPlayerProps = TPlayerProps & {
  playerType: PlayerType
}
